import { useState, useEffect, useRef, useMemo } from 'react';
import { Button, Box, Stack, Typography } from '@mui/joy';
import { SelfImprovement } from '@mui/icons-material';

import background from './images/japanese-landscape.webp';
import startAudio from './audios/main-gong.mp3';
import interimAudio from './audios/short-gong.m4a';
import endAudio from './audios/crescendo.m4a';
import NoSleep from 'nosleep.js';

function App() {
  const initialTime = 11 * 60 + 11; // 11 mins and 11 secs
  const [timer, setTimer] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const audioContextRef = useRef(null);
  const startBufferRef = useRef(null);
  const halfwayBufferRef = useRef(null);
  const endBufferRef = useRef(null);
  const noSleep = useMemo(() => new NoSleep(), []);

  const loadAudio = async (audioContext, audioPath, bufferRef) => {
    const response = await fetch(audioPath);
    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    bufferRef.current = audioBuffer;
  };

  const playAudio = audioBuffer => {
    if (audioContextRef.current && audioBuffer) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContextRef.current.destination);
      source.start();
    }
  };

  useEffect(() => {
    audioContextRef.current = new AudioContext();
    loadAudio(audioContextRef.current, startAudio, startBufferRef);
    loadAudio(audioContextRef.current, interimAudio, halfwayBufferRef);
    loadAudio(audioContextRef.current, endAudio, endBufferRef);

    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    let intervalId;
    if (isPlaying) {
      noSleep.enable(); // Enable wake lock.
      playAudio(startBufferRef.current);

      intervalId = setInterval(() => {
        setTimer(prevTimer => {
          if (prevTimer === Math.floor(initialTime / 2)) {
            // Check if timer is at halfway
            playAudio(halfwayBufferRef.current);
          }
          if (prevTimer <= 1) {
            clearInterval(intervalId);
            playAudio(endBufferRef.current);
            setIsPlaying(false);
            noSleep.disable(); // Disable wake lock when not playing.
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    } else {
      noSleep.disable(); // Ensure wake lock is disabled when not playing.
    }
    return () => {
      clearInterval(intervalId);
      noSleep.disable(); // Clean up wake lock when component unmounts.
    };
  }, [isPlaying, noSleep, initialTime]); // now including noSleep as a dependency

  const startMeditation = () => {
    if (!isPlaying) {
      setTimer(initialTime);
      setIsPlaying(true);
    }
  };

  const formatTime = timer => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <Box
      sx={{
        height: '100vh',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Stack alignItems="center">
        {isPlaying ? (
          <Typography
            level="h1"
            sx={{
              textShadow: '3px 3px 8px rgba(0,0,0,0.8)',
              fontSize: 55,
              fontFamily: 'Noto Sans JP',
              color: '#FFFFFF'
            }}
          >
            {formatTime(timer)}
          </Typography>
        ) : (
          <Button
            variant="solid"
            size="lg"
            onClick={startMeditation}
            sx={{ fontSize: 22 }}
            startDecorator={<SelfImprovement sx={{ fontSize: 33 }} />}
          >
            Begin
          </Button>
        )}
      </Stack>
    </Box>
  );
}

export default App;
